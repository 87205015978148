import React, { useCallback, useEffect, useState } from "react";
import RegisterContent from "../../components/RegisterContent";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { graphql, navigate, Script } from "gatsby";
import Layout from "../../components/layout/Layout";
import { getQueryString } from "../../utils/helper";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";
import { useLocation } from "@reach/router";

const Register = () => {
  const location = useLocation();
  const { isLoggedIn, userId, email } = useJWTChecker();
  const [userStatus, setUserStatus] = useState(true); // {*** FALSE -> Register ** TRUE -> Login ***}
  const [showMyAccount, setShowMyAccount] = useState(false);

  useEffect(() => {
    console.log("first")
    // console.log("jwtChecker userDetails", isLoggedIn, userId, email);
    if (isLoggedIn && email) {
      setShowMyAccount(true);
    } else {
      setShowMyAccount(false);
    }
  }, [isLoggedIn, email]);

  const setAuthType = useCallback(() => {
    const redirectUrl = getQueryString("r");

    let url = "/login";

    if (redirectUrl) {
      url = `${url}?r=${redirectUrl}`;
    }

    navigate(url + location.search);
  }, []);

  return (
    <Layout>
      <RegisterContent goToLogin={setAuthType} />
    </Layout>
  );
};

export const query = graphql`
	query {
		wp {
			isSeoEnabled
      isSearchEngineEnabled
		}
	}
`;

export const Head = ({ data }: any) => {
  const isSearchEnabled = data.wp.isSearchEngineEnabled;
  const location = useLocation();
  return (
    <>
      <title>Register</title>
      <link
        rel="canonical"
        href={`${location.origin}${location.pathname}`}
      ></link>
      <meta name="robots" content={!isSearchEnabled ? "noindex, nofollow" : "index, follow"} />
    </>
  );
};

export default Register;
